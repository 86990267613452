import * as React from "react"
import {useSpring} from 'react-spring'
import {AnimatedButtonProps} from 'src/components/AnimatedButton'
import ProductButton from 'src/components/ProductButton'
import LinksLayout from 'src/components/LinksLayout'
import {fadeInFromLeftConfig, fadeInFromRightConfig} from 'src/utils/animations'

const DeskChair = (props) => {
  const fadeInFromLeft = useSpring(fadeInFromLeftConfig)
  const fadeInFromRight = useSpring(fadeInFromRightConfig)

  const getFluidImage = (imageName: string) => (
    props.data[imageName].childImageSharp.fluid
  )

  const productList: AnimatedButtonProps[] = [
    {
      backgroundImageData: getFluidImage('Chair'),
      link: 'https://amzn.to/3LVzfIV',
      fadeSpringClass: fadeInFromLeft,
      header: 'Herman Miller Embody',
    },
    {
      backgroundImageData: getFluidImage('Frame'),
      link: 'https://amzn.to/3FnE1fI',
      fadeSpringClass: fadeInFromRight,
      header: 'Progressive Desk Standing Frame',
    },
    {
      backgroundImageData: getFluidImage('Drawer'),
      link: 'https://amzn.to/3weF6Uj',
      fadeSpringClass: fadeInFromLeft,
      header: 'Alex Drawer',
    },
    {
      backgroundImageData: getFluidImage('Tabletop'),
      link: 'https://amzn.to/3weCGVC',
      fadeSpringClass: fadeInFromRight,
      header: 'Lorell 72" Tabletop',
    },
  ]

  
  return (
    <LinksLayout>
      {productList.map((linkData) => <ProductButton {...linkData}/> )}
    </LinksLayout>
  )
}

export default DeskChair

export const query = graphql`
  query {
    Keyboard: file(relativePath: { eq: "setup/keyboardmouse/keyboard.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    Mouse: file(relativePath: { eq: "setup/keyboardmouse/mouse.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    Chair: file(relativePath: { eq: "setup/deskchair/chair.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    Frame: file(relativePath: { eq: "setup/deskchair/frame.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    Drawer: file(relativePath: { eq: "setup/deskchair/drawer.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    Tabletop: file(relativePath: { eq: "setup/deskchair/tabletop.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
  }
`